import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; 
import Layout from './components/Layout';
import SignupPage from './pages/SignupPage';
import LoginPage from './pages/LoginPage';
import SearchResultsPage from './pages/SearchResultsPage'; // Importer la page des résultats
import TripDetailsPage from './pages/TripDetailsPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/inscription" element={<Layout><SignupPage /></Layout>} />
        <Route path="/connexion" element={<Layout><LoginPage /></Layout>} />
        {/* <Route path="/rechercher" element={<Layout><SearchResultsPage /></Layout>} /> */}
        <Route path="/resultats" element={<Layout><SearchResultsPage /></Layout>} /> {/* Route vers la page des résultats */}
        <Route path="/resultats/:id" element={<Layout><TripDetailsPage /></Layout>} /> {/* Route vers la page de détail */}
      </Routes>
    </Router>
  );
};

export default App;
