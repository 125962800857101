import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import Input from '../components/Input';

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Logique de connexion à implémenter
    console.log(formData);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Connexion</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email */}
          <Input label="Email" type="email" name="email" value={formData.email} onChange={handleChange} required />

          {/* Mot de passe */}
          <Input label="Mot de passe" type="password" name="password" value={formData.password} onChange={handleChange} required
          />

          {/* Bouton de soumission */}
          <div>
            <button type="submit" className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition-colors">Se connecter</button>
          </div>
        </form>

        {/* Séparateur */}
        <div className="flex items-center my-6">
          <hr className="flex-grow border-gray-300" />
          <span className="mx-2 text-gray-500">ou</span>
          <hr className="flex-grow border-gray-300" />
        </div>

        {/* Connexion avec Google et Facebook */}
        <div className="space-y-4">
          {/* Connexion avec Google */}
          <button className="w-full flex items-center justify-center space-x-2 border p-3 rounded-md hover:bg-gray-100 transition-colors">
            <FcGoogle className="text-2xl" />
            <span>Continuer avec Google</span>
          </button>

          {/* Connexion avec Facebook */}
          <button className="w-full flex items-center justify-center space-x-2 border p-3 rounded-md hover:bg-gray-100 transition-colors">
            <FaFacebook className="text-2xl text-blue-600" />
            <span>Continuer avec Facebook</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
