import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa'; // Icone utilisateur

interface Trip {
  id: number;
  departure: string;
  arrival: string;
  transportMode: string;
  price: number;
  date: string;
  departureTime: string;
  arrivalTime: string;
  duration: string;
  pricePerKilo?: number; // Prix par kilo
  availableKilos?: number; // Kilos disponibles pour le transport
}

const TripDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const trip: Trip = location.state?.trip;

  if (!trip) {
    return <p>Trajet non trouvé</p>;
  }

  const handleReservationClick = () => {
    // Rediriger vers la page de réservation avec les détails du trajet
    navigate(`/reservation/${trip.id}`, { state: { trip } });
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        {/* Date et heure de départ affichée en H1 */}
        <h1 className="text-3xl font-bold text-center mb-6">
          {trip.date} à {trip.departureTime}
        </h1>

        {/* Première barre horizontale grise */}
        <hr className="border-t-4 border-gray-400 mb-6" />

        {/* Bloc entre les deux barres grises avec les infos du trajet */}
        <div className="flex justify-between items-center text-center mb-6">
          {/* Ville de départ et heure */}
          <div>
            <p className="text-lg font-bold">{trip.departure}</p>
            <p className="text-gray-500">{trip.departureTime}</p>
          </div>

          {/* Trait vertical */}
          <div className="w-px h-12 bg-gray-400"></div>

          {/* Durée du trajet */}
          <div>
            <p className="text-lg font-bold">{trip.duration}</p>
          </div>

          {/* Trait vertical */}
          <div className="w-px h-12 bg-gray-400"></div>

          {/* Ville d'arrivée et heure */}
          <div>
            <p className="text-lg font-bold">{trip.arrival}</p>
            <p className="text-gray-500">{trip.arrivalTime}</p>
          </div>
        </div>

        {/* Deuxième barre horizontale grise */}
        <hr className="border-t-4 border-gray-400 mb-6" />

        {/* Section avec l'icône du transporteur, le prix par kilo, et les kilos disponibles */}
        <div className="justify-between items-center mb-6">
          {/* Icone du transporteur */}
          <div className="flex items-center space-x-4">
            <FaUser className="text-gray-600" size={48} /> {/* Icone de l'utilisateur */}
            <span className="text-lg font-semibold">Transporteur</span>
          </div>

          {/* Prix par kilo */}
          <div>
            <p className="text-xl font-bold text-gray-700">
              {trip.pricePerKilo ? `${trip.pricePerKilo} €/kg` : 'Prix au kilo non disponible'}
            </p>
          </div>

          {/* Kilos disponibles */}
          <div>
            <p className="text-lg font-semibold text-gray-700">
              {trip.availableKilos ? `${trip.availableKilos} kg disponibles` : 'Capacité non spécifiée'}
            </p>
          </div>
        </div>

        {/* Bouton de réservation */}
        <button
          onClick={handleReservationClick}
          className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition-colors"
        >
          Faire une réservation
        </button>
      </div>
    </div>
  );
};

export default TripDetailsPage;
