import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa'; 
import { FaCar, FaTrain, FaMotorcycle, FaTruck, FaPlane } from 'react-icons/fa';

// Interface pour les voyages
interface Trip {
  id: number;
  departure: string;
  arrival: string;
  transportMode: 'voiture' | 'train' | 'moto' | 'camion' | 'avion';
  price: number;
  date: string;
  departureTime: string;
  arrivalTime: string;
  duration: string;
  totalKg: number;
  pricePerKilo: number;
}

// Dictionnaire d'icônes pour les moyens de transport
const transportIcons: Record<string, JSX.Element> = {
  voiture: <FaCar className="text-blue-500" size={24} />,
  train: <FaTrain className="text-green-500" size={24} />,
  moto: <FaMotorcycle className="text-red-500" size={24} />,
  camion: <FaTruck className="text-yellow-500" size={24} />,
  avion: <FaPlane className="text-purple-500" size={24} />,
};

const SearchResultsPage: React.FC = () => {
  const location = useLocation();
  const [trips, setTrips] = useState<Trip[]>([]);
  const [searchCriteria, setSearchCriteria] = useState({
    departureLocation: '',
    arrivalLocation: '',
    departureDate: '',
    transportMode: '',
  });

  useEffect(() => {
    if (location.state) {
      const { trips, departureLocation, arrivalLocation, departureDate, transportMode } = location.state;
      setTrips(trips || []);
      setSearchCriteria({
        departureLocation: departureLocation || '',
        arrivalLocation: arrivalLocation || '',
        departureDate: departureDate || '',
        transportMode: transportMode || '',
      });
    }
  }, [location.state]);

  return (
    <div className="grid grid-cols-12 gap-4 min-h-screen bg-gray-100">
      {/* Colonne vide à gauche */}
      <div className="col-span-2"></div>

      {/* Contenu central - 8 colonnes */}
      <div className="col-span-8 grid grid-cols-8 gap-4">
        {/* Critères de recherche - 2 colonnes */}
        <div className="col-span-2 bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Critères de recherche</h2>
          <div className="space-y-2">
            <p className="text-gray-700">Départ : {searchCriteria.departureLocation}</p>
            <p className="text-gray-700">Arrivée : {searchCriteria.arrivalLocation}</p>
            <p className="text-gray-700">Date : {searchCriteria.departureDate}</p>
            <p className="text-gray-700">Moyen de transport : {searchCriteria.transportMode}</p>
          </div>
        </div>

        {/* Résultats de recherche - 6 colonnes */}
        <div className="col-span-6">
          <h2 className="text-2xl font-bold mb-6">Résultats de la recherche</h2>

          {trips.length > 0 ? (
            <ul className="space-y-4">
              {trips.map((trip) => (
                <li key={trip.id}>
                  <Link
                    to={`/resultats/${trip.id}`}
                    state={{ trip }} // Passer le détail du trajet via l'état
                    className="block p-6 bg-white rounded-lg shadow-md border border-transparent hover:border-blue-500 hover:border-4 transition-colors relative"
                  >
                    <div className="grid grid-cols-6 gap-4">
                      {/* Colonne 1: Heure et ville de départ */}
                      <div className="text-center">
                        <p className="text-xl font-bold">{trip.departureTime}</p>
                        <p className="text-sm text-gray-600">{trip.departure}</p>
                      </div>

                      {/* Colonne 2 et 3 : Barre horizontale avec les cercles gris */}
                      <div className="col-span-2 flex items-center justify-center">
                        <div className="flex items-center w-3/4 relative">
                          <div className="w-full h-1 bg-gray-300 relative">
                            <div className="absolute left-0 h-4 w-4 bg-gray-500 rounded-full"></div> {/* Point de départ en gris */}
                            <div className="absolute right-0 h-4 w-4 bg-gray-500 rounded-full"></div> {/* Point d'arrivée en gris */}
                          </div>
                          <span className="absolute w-full text-center -top-5 text-sm text-gray-500">{trip.duration}</span>
                        </div>
                      </div>

                      {/* Colonne 4 : Heure et ville d'arrivée */}
                      <div className="text-center">
                        <p className="text-xl font-bold">{trip.arrivalTime}</p>
                        <p className="text-sm text-gray-600">{trip.arrival}</p>
                      </div>

                      {/* Colonne 5 : Vide */}
                      <div></div>

                      {/* Colonne 6: Nombre total de kilos */}
                      <div className="flex flex-col justify-between items-end">
                        <span className="text-gray-600 text-sm font-semibold">{trip.totalKg} kg disponibles</span>
                        <p className="text-lg font-bold text-gray-800">{trip.pricePerKilo} €/kg</p>
                      </div>
                    </div>

                    <hr className="my-4" />

                    {/* Bloc contenant l'icône du transport, transporteur et le mode de transport */}
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        {/* Icone du moyen de transport */}
                        {transportIcons[trip.transportMode] || <FaCar />}
                        {/* Mode de transport */}
                        <p className="text-sm text-gray-600">{trip.transportMode}</p>
                        {/* Transporteur */}
                        <FaUser className="text-gray-600" size={24} /> {/* Icone du transporteur */}
                        <p className="text-sm text-gray-600">Transporteur</p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucun voyage trouvé pour ces critères.</p>
          )}
        </div>
      </div>

      {/* Colonne vide à droite */}
      <div className="col-span-2"></div>
    </div>
  );
};

export default SearchResultsPage;
