import React, { useState, useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import Input from '../components/Input';
import { ImCross } from 'react-icons/im';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const SignupPage: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [sChar, setSChar] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const { password, confirmPassword } = formData;

    // Vérification des majuscules et minuscules
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }

    // Vérification des chiffres
    if (password.match(/[0-9]/)) {
      setNum(true);
    } else {
      setNum(false);
    }

    // Vérification des caractères spéciaux
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setSChar(true);
    } else {
      setSChar(false);
    }

    // Vérification de la longueur du mot de passe
    if (password.length >= 6) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }

    // Vérification si les mots de passe correspondent
    setPasswordMatch(password === confirmPassword);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Logique pour soumettre le formulaire
    if (passwordMatch && uCase && num && sChar && passLength) {
      console.log(formData);
    } else {
      console.log('Erreur dans la validation du formulaire');
    }
  };

  const timesIcon = <ImCross size={15} color="red" />;
  const checkIcon = <IoIosCheckmarkCircle size={15} color="green" />;

  const switchIcon = (condition: boolean) => {
    return condition ? checkIcon : timesIcon;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Colonne gauche - Formulaire */}
        <div>
          <h2 className="text-2xl font-bold text-center mb-6">Inscription</h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Nom */}
            <Input label="Nom" type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />

            {/* Prénom */}
            <Input label="Prénom" type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />

            {/* Email */}
            <Input label="Email" type="email" name="email" value={formData.email} onChange={handleChange} required />

            {/* Mot de passe */}
            <Input label="Mot de passe" type="password" name="password" value={formData.password} onChange={handleChange} required />

            {/* Confirmation du mot de passe */}
            <Input
              label="Confirmation du mot de passe"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />

            {/* Validation des critères de mot de passe */}
            <div className="bg-gray-100 p-4 rounded-md">
              <ul className="space-y-2">
                <li className="flex items-center">
                  {switchIcon(uCase)} &nbsp; Majuscule et minuscule
                </li>
                <li className="flex items-center">
                  {switchIcon(num)} &nbsp; Un chiffre (0-9)
                </li>
                <li className="flex items-center">
                  {switchIcon(sChar)} &nbsp; Un caractère spécial (!@#$%^&*)
                </li>
                <li className="flex items-center">
                  {switchIcon(passLength)} &nbsp; Au moins 6 caractères
                </li>
                <li className="flex items-center">
                  {switchIcon(passwordMatch)} &nbsp; Confirmation du mot de passe correspondante
                </li>
              </ul>
            </div>

            {/* Bouton de soumission */}
            <div>
              <button
                type="submit"
                className={`w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition-colors ${
                  !passwordMatch || !uCase || !num || !sChar || !passLength ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!passwordMatch || !uCase || !num || !sChar || !passLength}
              >
                S'inscrire
              </button>
            </div>
          </form>
        </div>

        {/* Colonne droite - Connexion avec Google et Facebook */}
        <div className="flex flex-col justify-center space-y-4">
          {/* Bouton Google */}
          <button className="w-full flex items-center justify-center space-x-2 border p-3 rounded-md hover:bg-gray-100 transition-colors">
            <FcGoogle className="text-2xl" />
            <span>Continuer avec Google</span>
          </button>

          {/* Bouton Facebook */}
          <button className="w-full flex items-center justify-center space-x-2 border p-3 rounded-md hover:bg-gray-100 transition-colors">
            <FaFacebook className="text-2xl text-blue-600" />
            <span>Continuer avec Facebook</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
