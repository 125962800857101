import React, { useState, useEffect, useRef } from 'react';
import { FiPlus, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null); // Référence pour le menu déroulant

  // Fonction pour basculer le menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Fonction pour fermer le menu si on clique en dehors
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false); // Fermer le menu si on clique en dehors
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-png.png`} // Le chemin vers votre logo
            alt="Logo Transkage"
            className="h-15 w-60" // Ajustez la hauteur du logo
          />
        </div>

        <div className="flex items-center space-x-6">
          {/* Lien Publier un trajet */}
          <Link
            to="/publish"
            className="flex items-center text-gray-700 hover:text-blue-600 font-semibold"
          >
            <FiPlus className="mr-2" /> Publier un trajet
          </Link>

          {/* Icone utilisateur avec le toggle menu */}
          <div className="relative" ref={menuRef}>
            <button onClick={toggleMenu} className="flex items-center focus:outline-none">
              <FiUser className="text-2xl text-gray-700 hover:text-blue-600 rounded-full border border-gray-400 p-1" />
            </button>

            {/* Dropdown menu */}
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg py-2">
                <Link
                  to="/inscription"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Inscription
                </Link>
                <Link
                  to="/connexion"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Connexion
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
