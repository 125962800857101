import React from 'react';

interface InputProps {
  label: string;
  type: 'text' | 'email' | 'password' | 'date';
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({ label, type, name, value, onChange, required = false }) => {
  return (
    <div>
      <label className="block mb-1 text-gray-600">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-500 text-black"
      />
    </div>
  );
};

export default Input;
