import React from 'react';

const ServicesSection: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Pourquoi choisir Transkage ?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4">Transporteurs fiables</h3>
            <p>Nos transporteurs sont vérifiés et évalués pour garantir un service de qualité.</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4">Livraisons rapides</h3>
            <p>Envoyez vos colis rapidement grâce à notre réseau de transporteurs partout en France.</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4">Support 24/7</h3>
            <p>Notre équipe est disponible à tout moment pour répondre à vos questions.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
