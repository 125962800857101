import React, { useState } from 'react';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import MaintenancePopup from '../components/MaintenancePopup'; // Importation du popup

const Home: React.FC = () => {
  const [isMaintenance] = useState(true); // Indicateur de maintenance

  return (
    <div>
      {isMaintenance && <MaintenancePopup />} {/* Affiche le popup si en maintenance */}
      <HeroSection />
      <ServicesSection />
    </div>
  );
};

export default Home;
