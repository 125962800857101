import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroSection: React.FC = () => {
  const [departureLocation, setDepartureLocation] = useState('');
  const [arrivalLocation, setArrivalLocation] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [transportMode, setTransportMode] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Simuler les résultats de recherche (données factices)
    const trips = [
      { id: 1, departure: 'Paris', arrival: 'Lyon', transportMode: 'voiture', price: 30, date: '2024/09/25', departureTime: '08:00', arrivalTime: '10:30', duration: '2h 30m', pricePerKilo: 20, totalKg: 15 },
      { id: 2, departure: 'Paris', arrival: 'Lyon', transportMode: 'train', price: 50, date: '2024/09/26', departureTime: '14:00', arrivalTime: '16:00', duration: '2h', pricePerKilo: 16, totalKg: 21 },
      // Ajoutez plus de trajets si nécessaire
    ];

    // Rediriger vers la page des résultats en transmettant à la fois les critères de recherche et les trajets
    navigate('/resultats', {
      state: {
        departureLocation,
        arrivalLocation,
        departureDate,
        transportMode,
        trips, // Passer les résultats des trajets
      },
    });
  };

  return (
    <div className="bg-blue-500 text-white py-20">
      <div className="container mx-auto text-center">
        <h1 className="text-4xl font-bold mb-4">Envoyez vos colis facilement avec Transkage</h1>
        <p className="mb-8">Connectez-vous avec des transporteurs fiables pour envoyer vos colis partout dans le monde.</p>

        <div className="bg-white p-6 rounded-full shadow-md inline-block">
          <form onSubmit={handleSubmit} className="flex space-x-4 items-center">
            <input
              type="text"
              placeholder="D'où partez-vous ?"
              value={departureLocation}
              onChange={(e) => setDepartureLocation(e.target.value)}
              className="w-1/4 p-4 border rounded-full text-black focus:outline-none"
            />
            <input
              type="text"
              placeholder="Destination"
              value={arrivalLocation}
              onChange={(e) => setArrivalLocation(e.target.value)}
              className="w-1/4 p-4 border rounded-full text-black focus:outline-none"
            />
            <input
              type="date"
              value={departureDate}
              onChange={(e) => setDepartureDate(e.target.value)}
              className="w-1/4 p-4 border rounded-full text-black focus:outline-none"
            />
            <select
              value={transportMode}
              onChange={(e) => setTransportMode(e.target.value)}
              className="w-1/4 p-4 border rounded-full text-black focus:outline-none"
            >
              <option value="">Moyen de transport</option>
              <option value="voiture">Voiture</option>
              <option value="camion">Camion</option>
              <option value="moto">Moto</option>
              <option value="train">Train</option>
              <option value="avion">Avion</option>
            </select>
            <button type="submit" className="bg-blue-600 text-white px-6 py-4 rounded-full">
              Rechercher
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
