import React from 'react';

const MaintenancePopup: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg text-center max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Site en Maintenance</h2>
        <p className="mb-8">Nous sommes actuellement en maintenance. Veuillez revenir au plus tard mi-octobre 2024 pour commencer vos transactions. Merci pour votre compréhension !</p>
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded-full"
          onClick={() => alert("Le bouton ne fait rien car le site est en maintenance.")} // Optionnel
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default MaintenancePopup;
