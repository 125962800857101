import React from 'react';
import Header from './Header';  // Import du Header
import Footer from './Footer';  // Import du Footer

type LayoutProps = {
  children: React.ReactNode;  // Cette propriété va contenir le contenu des différentes pages
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header commun */}
      <Header />
      
      {/* Contenu principal spécifique à chaque page */}
      <main className="flex-grow">
        {children}
      </main>
      
      {/* Footer commun */}
      <Footer />
    </div>
  );
};

export default Layout;
